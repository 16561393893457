import React from "react";
import solutionsHeader from "../assets/images/solutions-header.png";
import solutionsHeader2 from "../assets/images/solutions-header-2.png";
import plusIcon from "../assets/icons/plus.png";
import minusIcon from "../assets/icons/minus.png";
import solutionSide1 from "../assets/images/solutions-side-1.png";
import solutionSide2 from "../assets/images/solutions-side-2.png";

import solution1 from "../assets/images/abc-01.png";
import solution2 from "../assets/images/abc-02.png";
import solution3 from "../assets/images/abc-03.png";
import lineCurve from "../assets/images/line-curve.png";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    EffectCreative,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
} from "@chakra-ui/react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function TenthPage() {
    const [activeSection, setActiveSection] = React.useState("section1");

    const handleClick = (index) => {
        setActiveSection(index);
    };

    const [isSectionOneClicked, setIsSectionOneClicked] = React.useState(false);
    const [isSectionTwoClicked, setIsSectionTwoClicked] = React.useState(false);

    const handleSectionOneClick = () => {
        setIsSectionOneClicked(!isSectionOneClicked);
    };

    const handleSectionTwoClick = () => {
        setIsSectionTwoClicked(!isSectionTwoClicked);
    };

    const navigate = useNavigate();

    const [isSmall, setIsSmall] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth <= 1100);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820918/nutramed/solutions-header_reszqn.png"
                    className="w-full"
                    alt="Solutions Header"
                />
                <h1
                    className="absolute text-white font-montserrat font-bold
                    2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]
                    2xl:top-[40%] 2xl:left-48 xl:top-[30%] xl:left-24 lg:top-[30%] lg:left-16 md:top-[25%] md:left-12 sm:top-[25%] sm:left-8 top-[25%] left-6"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                >
                    SOLUTIONS
                </h1>
            </div>
            <div className="text-center w-full pb-8 flex self-center flex-col bottom-20 items-center font-roboto justify-center">
                <div
                    className="flex py-12 flex-col
                2x:px-20 xl:px-16 lg:px-12 md:px-8 sm:px-4 px-2"
                >
                    <h1 className="text-[24px] font-montserrat font-bold text-center mt-8">
                        NUTRAMED CORPORATION
                    </h1>
                    <h2 className="text-[16px] font-montserrat font-semibold text-center">
                        BRINGING SCIENCE TO LIFE
                    </h2>
                    <div>
                        <p
                            className="text-[14px] text-gray-500 font-roboto font-normal text-center mt-4
                        2xl:px-32 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-2"
                        >
                            Nutramed continually strives to maintain an
                            unwavering reputation for the delivery of
                            exceptional products, services, and solutions where
                        </p>
                        <p
                            className="text-[14px] text-gray-500 font-roboto font-normal text-center
                        2xl:px-32 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-2"
                        >
                            quality and dependability are of the greatest
                            importance.
                        </p>
                    </div>
                </div>
            </div>
            <img src={lineCurve} alt="line-curve" className="w-full px-12" />

            {/* //section 1 */}
            <ScrollAnimation
                animateOnce
                animateIn="fadeInLeft"
                className="shadow-lg flex flex-col justify-center h-[50rem] bg-white
                2xl:px-52 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div
                    className={`flex ${
                        isSmall ? "flex-col space-y-8" : "flex-row"
                    } min-h-[25rem] items-center`}
                >
                    <div className="flex flex-col h-full">
                        <div className="flex flex-col h-2/3 justify-center">
                            <p className="text-[14px] text-gray-500 font-montserrat font-normal">
                                Pharmaceutical and nutraceutical
                            </p>
                            <h1 className="text-[42px] text-gray-800 font-montserrat font-bold">
                                FACTORIES
                            </h1>
                            <ul className="list-disc flex justify-between px-4 pr-12 items-center pb-2">
                                <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                    Vung Tau and Long An Province
                                </li>
                                <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                    GMP and USFDA certified
                                </li>
                                <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                    5,000m²
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-1 h-1/3 mt-2">
                            <p className="text-[14px] pr-12 text-justify text-black font-roboto font-normal">
                                Equipped with advanced facilities including
                                storage warehouses, specialized preparation
                                areas, and enclosed extraction units with
                                cutting-edge automated production lines, we
                                combine innovation and efficiency to deliver
                                high-quality products swiftly and reliably.
                            </p>
                        </div>
                    </div>
                    <img src={solution1} alt="solution-1" className="w-[50%]" />
                </div>
                <div className="flex pt-6 pb-12 w-full">
                    <Accordion className="w-full" allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Dosage forms portfolio
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Tablets, capsules, powders,
                                            solutions, and more.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Ingredients
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Our ingredients are thoroughly
                                            selected, purchased, and rigorously
                                            tested by a testing facility
                                            compliant with the Ministry of
                                            Health's GLP guidelines, ensuring
                                            that the ingredients meet strict
                                            quality criteria, including clear
                                            origin and compliance with the
                                            Vietnamese Pharmacopoeia standards.
                                        </Text>
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={2}
                                        >
                                            We also apply GACP, GCP, and GSP
                                            standards for importation,
                                            transportation, storage and
                                            preservation processes to control
                                            our imported medicinal materials.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Nutramed Commitment
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            We prioritize transparency, honesty,
                                            and ethical conduct in all aspects
                                            of operations.
                                        </Text>
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={2}
                                        >
                                            Upholding rigorous standards to
                                            drive innovation and deliver trusted
                                            healthcare solutions to every
                                            client, we are dedicated to
                                            environmental stewardship and
                                            sustainable practices throughout our
                                            product lifecycle.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </ScrollAnimation>

            {/* //section 2 */}
            <ScrollAnimation
                animateOnce
                animateIn="fadeInRight"
                className="shadow-lg flex flex-col justify-center h-[50rem] mt-8 bg-white
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div
                    className={`flex ${
                        isSmall ? "flex-col space-y-8" : "flex-row space-x-12"
                    } min-h-[25rem] items-center`}
                >
                    <img src={solution2} alt="solution-2" className="w-[50%]" />
                    <div className="flex flex-col h-full">
                        <div className="flex flex-col w-full h-2/3 justify-center">
                            <h1 className="text-[42px] text-gray-800 font-montserrat font-bold">
                                LABORATORIES
                            </h1>
                            <div className="space-y-2">
                                <ul className="list-disc w-full flex space-x-12 px-4">
                                    <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                        Canada
                                    </li>
                                    <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                        GMPWHO
                                    </li>
                                </ul>
                                <ul className="text-[14px] px-4 list-disc text-gray-500 font-roboto font-normal">
                                    <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                        Facilities
                                    </li>
                                    <ul className="flex flex-col mt-1">
                                        <li className="flex space-x-2">
                                            <span className="text-gray-500">
                                                Analytical Lab
                                            </span>
                                        </li>
                                        <li className="flex space-x-2">
                                            <span className="text-gray-500">
                                                Microbiological Lab
                                            </span>
                                        </li>
                                        <li className="flex space-x-2">
                                            <span className="text-gray-500">
                                                Research and Development (R&D
                                                Lab)
                                            </span>
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div className="space-y-2 h-1/3 ml-4 pb-4">
                            <div className="space-y-2">
                                <p className="text-[14px] text-justify text-black font-roboto font-normal">
                                    Nutramed's laboratories are equipped with
                                    the latest technology and equipment to
                                    support our mission of delivering innovative
                                    and high-quality healthcare products. Our
                                    advanced laboratories ensure product
                                    excellence through comprehensive analytical,
                                    microbiological, and R&D capabilities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex pt-6 pb-12 justify-between">
                    <Accordion className="w-full" allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Analytical Lab
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Analytical lab provides accurate and
                                            rapid analytical testing services
                                            for vitamins, nutraceuticals, and
                                            nutritional supplements. It is a
                                            dust contained flexible facility
                                            designed for cost effective and
                                            efficient processing of all tests
                                            performed, ranging from premixes and
                                            supplements through to fortified and
                                            natural finished products.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Microbiological Lab
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            We conduct comprehensive
                                            microbiological testing at all
                                            production stages, including
                                            environmental monitoring,
                                            problem-solving and quality
                                            assurance. Our lab adheres to
                                            rigorous USDA and FDA regulations,
                                            employing validated methods to
                                            detect harmful microorganisms and
                                            pathogens.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Research and Development (R&D Lab)
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Our team of experienced PhD
                                            scientists provides clients with
                                            cutting-edge formulations and
                                            ensures product consistency across
                                            various delivery methods, including
                                            liquids, capsules, tablets, and
                                            powders.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </ScrollAnimation>

            {/* //section 3 */}
            <ScrollAnimation
                animateOnce
                animateIn="fadeInLeft"
                className="shadow-lg flex flex-col justify-center h-[50rem] mt-8 bg-white
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div
                    className={`flex ${
                        isSmall ? "flex-col space-y-8" : "flex-row space-x-12"
                    } min-h-[28rem] items-center`}
                >
                    <div className="flex flex-col h-full">
                        <div className="flex h-2/3 flex-col justify-center">
                            <h1 className="text-[42px] text-gray-800 font-montserrat font-bold">
                                R&D CENTER
                            </h1>
                            <ul className="list-disc px-4">
                                <li className="text-[14px] text-gray-500 font-roboto font-normal">
                                    {" "}
                                    Vancouver, Canada
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4 h-1/3 pt-4">
                            <p className="text-[14px] text-justify text-black font-roboto font-normal">
                                Nutramed's Canadian laboratories, adhering to
                                GMP-WHO standards, are at the forefront of
                                healthcare innovation. Our advanced analytical,
                                microbiological, and research and development
                                capabilities facilitate the creation and
                                rigorous evaluation of Nutramed's superior
                                products.
                            </p>
                        </div>
                    </div>
                    <img src={solution3} alt="solution-3" className="w-[50%]" />
                </div>
                <div className="flex pt-6 pb-12 justify-between">
                    <Accordion className="w-full mt-8" allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Human Resource
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Hub of interdisciplinary expertise,
                                            housing specialists in medicine,
                                            pharmacology, physical chemistry,
                                            biochemistry, immunology, biology,
                                            and beyond.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Nutramed Commitment
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="14px"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Quality and safety are the
                                            cornerstones of Nutramed's research
                                            and development. Investing in
                                            thorough testing and evaluation,
                                            Nutramed ensures our products meet
                                            or exceed current standards,
                                            providing our customers with
                                            reliable and effective healthcare
                                            solutions.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </ScrollAnimation>

            <ScrollAnimation
                animateOnce
                animateIn="fadeInUp"
                className="relative"
            >
                <div className="flex justify-center">
                    <img
                        src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820921/nutramed/solutions-header-2_hqxmcg.png"
                        className="w-[100%]"
                        alt="Solutions Header 2"
                    />
                </div>
                <div
                    className="absolute space-y-2 
                2xl:top-[50%] 2xl:left-[13%] xl:top-[40%] xl:left-[11%] lg:top-[35%] lg:left-[9%] md:top-[30%] md:left-[7%] sm:top-[25%] sm:left-[5%] top-[20%] left-[3%]"
                >
                    <h1
                        className=" text-white font-montserrat font-bold
                    2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] text-[12px]"
                    >
                        WE PROVIDE FULL-SERVICES
                    </h1>
                    <h2
                        className=" text-white font-montserrat font-bold
                    2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]"
                    >
                        SOLUTION
                    </h2>
                </div>
                <div className="flex items-center w-full justify-center]">
                    <div
                        className="absolute left-1/2 transform -translate-x-1/2 -bottom-[7%] flex h-[4rem] font-montserrat justify-between w-[81%] 
                    2xl:px-12 xl:px-8 lg:px-6 md:px-4 sm:px-2 px-2"
                    >
                        <div
                            className={`flex-1 hover:cursor-pointer flex font-semibold items-center justify-center text-[16px] transition-colors duration-300 ${
                                activeSection === "section1"
                                    ? "bg-gray-800 text-white"
                                    : "bg-gray-300 text-black"
                            }`}
                            onClick={() => handleClick("section1")}
                        >
                            Contract Manufacturing
                        </div>
                        <div
                            className={`flex-1 hover:cursor-pointer flex font-semibold items-center justify-center text-[16px] transition-colors duration-300 ${
                                activeSection === "section2"
                                    ? "bg-gray-800 text-white"
                                    : "bg-gray-300 text-black"
                            }`}
                            onClick={() => handleClick("section2")}
                        >
                            Private Label
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            {activeSection === "section1" && (
                <div
                    className="flex flex-col pb-12 shadow-xl
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
                >
                    <div
                        className="space-y-2 pb-8 pt-16
                        2xl:px-20 xl:px-12 lg:px-8 md:px-4 sm:px-2 px-2"
                    >
                        <p className="text-[16px] text-black font-bold font-roboto">
                            Planning
                        </p>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            Nutramed offers market research and product
                            development for health supplements. By understanding
                            our clients and their goals, we help them identify
                            your target market and develop a product launch plan
                            to achieve end-consumer product viability and
                            maximize profit potential.
                        </p>
                    </div>
                    <div
                        className="space-y-2
                    2xl:px-20 xl:px-12 lg:px-8 md:px-4 sm:px-2 px-2"
                    >
                        <p className="text-[16px] text-black font-bold font-roboto">
                            Formulation
                        </p>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            We assist clients in identifying the proper product
                            ingredients, and the optimal delivery medium.
                            Chemists, research scientists, pharmacists, and
                            product formulation managers at Nutramed apply cGMP
                            compliance methodologies to ensure the highest
                            quality and effectiveness for the consumers.
                        </p>
                    </div>
                    <div
                        className="space-y-2 pt-8
                    2xl:px-20 xl:px-12 lg:px-8 md:px-4 sm:px-2 px-2"
                    >
                        <p className="text-[16px] text-black font-bold font-roboto">
                            Labeling
                        </p>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            Nutramed provides label design, label printing, and
                            packaging services. We offer a variety of design
                            packaging that can specifically meet your needs.
                            Nutramed has an experienced team of ID and brand
                            product managers who are available to assist you in
                            developing packages, label designs, and overall
                            brand affinity for your products, which in turn
                            helps you bring a complete package to the end
                            consumer.
                        </p>
                        <div className="pt-4 space-y-4">
                            <div className="w-full">
                                <Accordion allowToggle>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton
                                                _hover={{ bg: "gray.200" }}
                                            >
                                                <Box
                                                    flex="1"
                                                    textAlign="left"
                                                    fontSize="lg"
                                                    fontWeight="bold"
                                                    fontFamily="Roboto"
                                                >
                                                    Formulation and Process
                                                    Development
                                                </Box>
                                                <AccordionIcon boxSize={6} />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Box className="flex space-x-4 justify-between">
                                                <Box className="flex-1 text-gray-500 flex flex-col">
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={0}
                                                    >
                                                        Brand extension
                                                        formulation development
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Dosage form
                                                        specification
                                                        development
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Blending, drying,
                                                        granulation, and coating
                                                        development
                                                    </Text>
                                                </Box>
                                                <Box className="flex-1 text-gray-500 flex flex-col">
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={0}
                                                    >
                                                        Excipient compatibility
                                                        studies
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Excipient selection and
                                                        ration determination
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Formulation of drug
                                                        products: solids and
                                                        semi-solids
                                                    </Text>
                                                </Box>
                                                <Box className="flex-1 text-gray-500 flex flex-col">
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={0}
                                                    >
                                                        Immediate release dosage
                                                        form development
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Manufacturing process
                                                        optimization
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Modified and sustained
                                                        release dosage form
                                                        development
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton
                                                _hover={{ bg: "gray.200" }}
                                            >
                                                <Box
                                                    flex="1"
                                                    textAlign="left"
                                                    fontSize="lg"
                                                    fontWeight="bold"
                                                    fontFamily="Roboto"
                                                >
                                                    Formulation Evaluation
                                                </Box>
                                                <AccordionIcon boxSize={6} />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Box className="flex space-x-4 justify-between">
                                                <Box className="flex-1 text-gray-500 flex flex-col">
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={0}
                                                    >
                                                        Compressibility
                                                        evaluation
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Content uniformity
                                                        evaluation
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Disintegration testing
                                                    </Text>
                                                </Box>
                                                <Box className="flex-1 text-gray-500 flex flex-col">
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={0}
                                                    >
                                                        Dissolution testing
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Friability testing
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        In-vitro bioequivalence
                                                        determination
                                                    </Text>
                                                </Box>
                                                <Box className="flex-1 text-gray-500 flex flex-col">
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={0}
                                                    >
                                                        Powder flow
                                                        determination
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Stability assessment
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily="Roboto"
                                                        mt={4}
                                                    >
                                                        Tap-density testing
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {activeSection === "section2" && (
                <div
                    className="flex flex-col shadow-xl pb-8
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
                >
                    <div
                        className="space-y-2 pb-8 pt-16
                        2xl:px-20 xl:px-12 lg:px-8 md:px-4 sm:px-2 px-2"
                    >
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            For years, Nutramed has been meticulously
                            manufacturing the highest-quality nutrition and
                            herbal products for companies. Comprehending the
                            lastest consumer trends, we provide products to
                            untangle their least to greatest concerns. We
                            already have a diverse portfolio of products that
                            covers dermatology, gastrointestinal health,
                            fertility support, muscle, bone, and joint health,
                            and more.
                        </p>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            Nutramed is here to fulfill what you seek. Let us
                            handle the complexities of product development and
                            manufacturing, allowing you to build your own brand.
                        </p>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            Contact us today to learn more about our private
                            label solutions.
                        </p>
                    </div>
                </div>
            )}
            <div
                className="flex h-full justify-evenly pb-12 mt-12
            2xl:space-x-20 xl:space-x-16 lg:space-x-12 md:space-x-8 sm:space-x-4
            2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <img
                    src={solutionSide1}
                    alt="solution-side-1"
                    className="w-[33%]"
                />
                <div className="flex flex-col w-2/3 justify-center items-start space-y-8">
                    <div className="">
                        <h1 className="text-[42px] font-montserrat font-bold">
                            Nutramed Corporation
                        </h1>
                        <h2 className="text-[24px] font-montserrat font-bold">
                            commits to high-quality products
                        </h2>
                    </div>
                    <div className="flex">
                        <button
                            onClick={() => {
                                navigate("/products");
                                window.scrollTo(0, 0);
                            }}
                            className="border-2 text-center w-full border-gray-400 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-semibold text-[16px] text-gray-500 px-8 py-2 rounded-md transform transition-colors duration-200"
                        >
                            VIEW ALL PRODUCTS
                        </button>
                    </div>
                </div>
            </div>
            <div
                className="text-center pb-8 items-center flex flex-col justify-center
            2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="flex flex-col text-center items-center justify-center italic">
                    <p className="text-[16px] text-gray-800 font-roboto font-normal">
                        We take care of all aspects of the final product, from
                        ensuring labeling and design meet health authority
                        specifications, to developing safety seals,
                    </p>
                    <p className="text-[16px] text-gray-800 font-roboto font-normal">
                        neck bands, and all other aspects of the final product
                        as needed. If you are interested in learning more about
                        Nutramed private label contract manufacturing services
                        and solutions, please contact us today.
                    </p>
                </div>
            </div>
            <div
                className={`flex ${
                    isSmall ? "flex-col space-y-12 justify-center items-center" : "flex-row items-center"
                } justify-between space-x-12 h-full bg-gray-200 py-20
            2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4`}
            >
                <div className="flex flex-col justify-center space-y-8">
                    <div className="space-y-4">
                        <h1 className="text-[24px] font-montserrat font-bold">
                            Clients
                        </h1>
                        <div className="border-b-2 border-b-white"></div>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            Putting customers at heart of everything we do,
                            Nutramed offers comprehensive solutions to elevate
                            your growing business.
                        </p>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-[24px] font-montserrat font-bold">
                            Distributors
                        </h1>
                        <div className="border-b-2 pr-12 border-b-white"></div>
                        <p className="text-[14px] text-gray-500 font-roboto font-normal">
                            We are seeking strategic distribution partners to
                            expand our global footprint and deliver superior
                            healthcare solutions to markets around the world.
                        </p>
                    </div>
                </div>
                <div
                    onClick={() => {
                        navigate("/contact-us");
                        window.scrollTo(0, 0);
                    }}
                    className={`${
                        isSmall
                            ? "max-w-[50%] min-w-[50%] w-full flex items-center"
                            : "max-w-[30%] min-w-[30%]"
                    }hover:cursor-pointer`}
                >
                    <img
                        src={solutionSide2}
                        alt="solution-side-1"
                        className="rounded-2xl hover:scale-105 transform duration-300"
                    />
                </div>
            </div>
        </div>
    );
}
