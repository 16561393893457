import React from "react";
import nutramedLogo from "../assets/images/nutramed_logo_01.png";
import globeIcon from "../assets/icons/globe.png";
import linkedinIcon from "../assets/icons/linkedin.png";
import facebookIcon from "../assets/icons/facebook.png";
import { useNavigate } from "react-router-dom";

export default function ContactMobile() {
    const navigate = useNavigate();

    const handleSectionClick = (page) => {
        navigate(page);
    };

    return (
        <div>
            <div className="border-b-2 border-gray-800 flex flex-col bg-gray-900 space-y-12 py-24 px-8 justify-between">
                <div className="flex flex-col font-montserrat justify-center pb-8 items-center">
                    <img
                        src={nutramedLogo}
                        alt="Nutramed Logo"
                        className="w-[134px] h-[100px]"
                    />
                </div>
                <div className="flex font-montserrat justify-between">
                    <div className="space-y-6">
                        <h1 className="text-sm font-semibold text-white">
                            QUICK LINKS
                        </h1>
                        <h1
                            onClick={() => {
                                handleSectionClick("/");
                            }}
                            className="text-sm text-white"
                        >
                            Home
                        </h1>
                        <h1
                            onClick={() => {
                                handleSectionClick("/about-us");
                            }}
                            className="text-sm hover:text-[#2E6D70] hover:underline text-white"
                        >
                            About Us
                        </h1>
                        <h1
                            onClick={() => {
                                handleSectionClick("/solutions");
                            }}
                            className="text-sm hover:text-[#2E6D70] hover:underline text-white"
                        >
                            Solutions
                        </h1>
                        <h1
                            onClick={() => {
                                handleSectionClick("/products");
                            }}
                            className="text-sm hover:text-[#2E6D70] hover:underline text-white"
                        >
                            Products
                        </h1>
                        <h1
                            onClick={() => {
                                handleSectionClick("/newsroom");
                            }}
                            className="text-sm hover:text-[#2E6D70] hover:underline text-white"
                        >
                            Newsroom
                        </h1>
                    </div>
                    <div className="space-y-6 w-[12rem]">
                        <h1 className="text-sm font-semibold text-white">
                            CONTACT US
                        </h1>
                        <p className="text-start flex flex-col text-white text-[14px]">
                            BUILDING 577 NGUYEN THI THAP, TAN PHONG WARD,
                            DISTRICT 7, HO CHI MINH CITY
                        </p>
                        <p className="text-start text-white text-[14px]">
                            Phone: (+84) 090 249 24 89
                        </p>
                        <p className="text-start text-white text-[14px]">
                            Email: infonutramed@gmail.com
                        </p>
                        <div className="flex w-[6rem] justify-between">
                            <img
                                src={globeIcon}
                                alt="Globe Icon"
                                className="w-5 h-5 filter invert"
                            />
                            <img
                                src={facebookIcon}
                                alt="Facebook Icon"
                                className="w-5 h-5 filter invert"
                            />
                            <img
                                src={linkedinIcon}
                                alt="Linkedin Icon"
                                className="w-5 h-5 filter invert"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
