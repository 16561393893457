import React from "react";
import { useNavigate } from "react-router-dom";

export default function NewsCard({ news }) {
    const formatDate = (date) => {
        const d = new Date(date);
        const formattedDate = `${d.getDate()}/${
            d.getMonth() + 1
        }/${d.getFullYear()}`;
        return formattedDate;
    };

    const navigate = useNavigate();

    const handleReadMoreClick = () => {
        navigate(`/news/${news.id}`);
        window.scrollTo(0, 0);
    };

    return (
        <div className="hover:cursor-default hover:shadow-lg transform duration-300 pb-4">
            <div className="h-48 w-full overflow-hidden">
                <img
                    className="h-full w-full object-cover"
                    src={news.thumbnail_url}
                    alt="place-holder"
                />
            </div>
            <div className="space-y-1 px-6 pt-4">
                <p className="font-montserrat text-[10px]">{news.tags}</p>
                <h1 className="text-[13px] font-montserrat line-clamp-2 font-bold">
                    {news.title}
                </h1>
                <div className="flex justify-between">
                    <p className="font-montserrat text-[12px] text-gray-500">
                        By {news.author}
                    </p>
                    <p className="font-montserrat text-[12px] text-gray-500">
                        {formatDate(news.published_date)}
                    </p>
                </div>
                <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                    {news.summary}
                </p>
            </div>
            <div className="flex pt-4 px-8 justify-end w-full">
                <div
                    onClick={handleReadMoreClick}
                    className="hover:cursor-pointer hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[13px] text-gray-500 rounded-md transform transition-colors duration-200"
                >
                    Read More
                </div>
            </div>
        </div>
    );
}
