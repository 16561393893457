import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ProductCard({ product }) {
    const navigate = useNavigate();
    const handleViewDetailsClick = () => {
        navigate(`/products/products-details-sample`);
        window.scrollTo(0, 0);
    };

    return (
        <div
            onClick={handleViewDetailsClick}
            className="flex hover:cursor-pointer flex-col justify-between hover:shadow-xl transform duration-300 hover:scale-105"
        >
            <img
                src="https://via.placeholder.com/250x250"
                alt={product.name}
                className="h-64 w-full object-cover"
            />
            <div className="flex flex-col justify-between h-full p-4">
                <div className="space-y-2">
                    <h1 className="text-[16px] text-start font-bold text-black hover:text-[#2E6D70] transition-colors duration-200">
                        {product.product_name}
                    </h1>
                    <div>
                        {product.benefits}
                    </div>
                </div>
            </div>
        </div>
    );
}
