import React from "react";
import ProductCard from "../components/ProductCard";
import productsHeader from "../assets/images/products-header.png";
import lineCurve from "../assets/images/line-curve.png";
import productBackGround_1 from "../assets/images/products-background-1.jpg";
import { Link, useNavigate } from "react-router-dom";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function EleventhPage() {
    const navigate = useNavigate();

    const handleViewProductsClick = () => {
        navigate("/products-list");
        window.scrollTo(0, 0);
    };

    return (
        <div>
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722823440/nutramed/products-header_cxkcsg.png"
                    className="w-full"
                    alt="Solutions Header"
                />
                <h1
                    className="absolute text-white font-montserrat font-bold
                    2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]
                    2xl:top-[40%] 2xl:left-48 xl:top-[30%] xl:left-24 lg:top-[30%] lg:left-16 md:top-[25%] md:left-12 sm:top-[25%] sm:left-8 top-[25%] left-6"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                >
                    PRODUCTS
                </h1>
            </div>
            <div className="flex mb-16 pt-4 flex-col">
                <h1 className="text-[24px] font-montserrat font-bold text-center mt-8">
                    NUTRAMED CORPORATION
                </h1>
                <h2 className="text-[16px] font-montserrat font-semibold text-center">
                    BRINGING SCIENCE TO LIFE
                </h2>
                <div>
                    <p
                        className="text-[14px] text-gray-500 font-roboto font-normal text-center mt-4
                    2xl:px-32 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-2"
                    >
                        Our products span several indications including
                        dermatology, gastrointestinal health, fertility support,
                        muscle, bone, and joint health, and more.
                    </p>
                    <p
                        onClick={() => {
                            navigate("/contact-us");
                            window.scrollTo(0, 0);
                        }}
                        className="text-[14px] hover:text-[#2E6D70] hover:cursor-pointer px-32 text-gray-500 font-roboto font-normal text-center mt-1"
                    >
                        Please contact us for our complete product catalogue
                    </p>
                </div>
            </div>
            <div className="relative flex items-center justify-center flex-col">
                <img
                    src={productBackGround_1}
                    className=""
                    alt="Product Background"
                />
                <div
                    animateIn="fadeInUp"
                    className="absolute w-full
                2xl:px-36 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-4"
                >
                    <div
                        className="flex flex-row pb-4 space-x-4 px-12
                    "
                    >
                        <ScrollAnimation
                            animateIn="fadeInLeft"
                            duration={1}
                            className="flex-1 bg-opacity-80 hover:scale-105 hover:bg-[#2E6D70] hover:cursor-default hover:text-white transform duration-300 rounded-tl-2xl items-center justify-center space-y-4 bg-white hover:shadow-lg flex flex-col
                            2xl:h-[12rem] xl:h-[10rem] lg:h-[8rem] md:h-[6rem] sm:h-[6rem] h-[6rem]"
                        >
                            <div
                                onClick={() => {
                                    navigate("/products-list");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:cursor-pointer h-full w-full flex flex-col justify-center items-center"
                            >
                                <h1 className="2xl:text-[24px] lg:text-[24px] md:text-[20px] sm:text-[16px] text-[14px] font-montserrat font-bold">
                                    Pharmaceuticals
                                </h1>
                                <div className="flex">
                                    <div
                                        className="hover:cursor-pointer italic bg-transparent font-roboto font-medium px-8 py-2 rounded-md
                                    2xl:text-[13px] xl:text-[13px] lg:text-[13px] sm:text-[11px] text-[11px]"
                                    >
                                        VIEW PRODUCTS
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInRight"
                            duration={1}
                            className="flex-1 bg-opacity-80 hover:scale-105 hover:bg-[#2E6D70] hover:cursor-default hover:text-white transform duration-300 rounded-tr-2xl items-center justify-center space-y-4 bg-white hover:shadow-lg flex flex-col
                            2xl:h-[12rem] xl:h-[10rem] lg:h-[8rem] md:h-[6rem] sm:h-[6rem] h-[6rem]"
                        >
                            <div
                                onClick={() => {
                                    navigate("/products-list");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:cursor-pointer h-full w-full flex flex-col justify-center items-center"
                            >
                                <h1 className="2xl:text-[24px] lg:text-[24px] md:text-[20px] sm:text-[16px] text-[14px] font-montserrat font-bold">
                                    Cosmetics
                                </h1>
                                <div className="flex">
                                    <div
                                        className="hover:cursor-pointer italic bg-transparent font-roboto font-medium px-8 py-2 rounded-md
                                    2xl:text-[13px] xl:text-[13px] lg:text-[13px] sm:text-[11px] text-[11px]"
                                    >
                                        VIEW PRODUCTS
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="flex flex-row px-12 space-x-4">
                        <ScrollAnimation
                            animateIn="fadeInLeft"
                            duration={1.5}
                            className="flex-1 bg-opacity-80 hover:scale-105 hover:bg-[#2E6D70] hover:cursor-default hover:text-white transform duration-300 rounded-bl-2xl items-center justify-center space-y-4 bg-white hover:shadow-lg flex flex-col
                            2xl:h-[12rem] xl:h-[10rem] lg:h-[8rem] md:h-[6rem] sm:h-[6rem] h-[6rem]"
                        >
                            <div
                                onClick={() => {
                                    navigate("/products-list");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:cursor-pointer h-full w-full flex flex-col justify-center items-center"
                            >
                                <h1 className="2xl:text-[24px] lg:text-[24px] md:text-[20px] sm:text-[16px] text-[14px] font-montserrat font-bold">
                                    Medical Equipment
                                </h1>
                                <div className="flex">
                                    <div
                                        className="hover:cursor-pointer italic bg-transparent font-roboto font-medium px-8 py-2 rounded-md
                                    2xl:text-[13px] xl:text-[13px] lg:text-[13px] sm:text-[11px] text-[11px]"
                                    >
                                        VIEW PRODUCTS
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInRight"
                            duration={1.5}
                            className="flex-1 bg-opacity-80 hover:scale-105 hover:bg-[#2E6D70] hover:cursor-default hover:text-white transform duration-300 rounded-br-2xl items-center justify-center space-y-4 bg-white hover:shadow-lg flex flex-col
                            2xl:h-[12rem] xl:h-[10rem] lg:h-[8rem] md:h-[6rem] sm:h-[6rem] h-[6rem]"
                        >
                            <div
                                onClick={() => {
                                    navigate("/products-list");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:cursor-pointer h-full w-full flex flex-col justify-center items-center"
                            >
                                <h1 className="2xl:text-[24px] lg:text-[24px] md:text-[20px] sm:text-[16px] text-[14px] font-montserrat font-bold">
                                    Health Supplements
                                </h1>
                                <div className="flex">
                                    <div
                                        className="hover:cursor-pointer italic bg-transparent font-roboto font-medium px-8 py-2 rounded-md
                                    2xl:text-[13px] xl:text-[13px] lg:text-[13px] sm:text-[11px] text-[11px]"
                                    >
                                        VIEW PRODUCTS
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    );
}
