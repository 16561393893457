import React from "react";
import NavBar from "./components/NavBar";
import FirstPage from "./pages/FirstPage";
import SecondPage from "./pages/SecondPage";
import ThirdPage from "./pages/ThirdPage";
import FourthPage from "./pages/FourthPage";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import FifthPage from "./pages/FifthPage";
import { Routes, Route } from "react-router-dom";
import SixthPage from "./pages/SixthPage";
import SeventhPage from "./pages/SeventhPage";
import EighthPage from "./pages/EighthPage";
import NinthPage from "./pages/NinthPage";
import TenthPage from "./pages/TenthPage";
import EleventhPage from "./pages/EleventhPage";
import ProductDetails from "./components/ProductDetails";

import FirstMobile from "./pages/FirstMobile";
import NavBarMobile from "./components/NavBarMobile";
import FooterMobile from "./components/FooterMobile";
import ContactMobile from "./components/ContactMobile";
import ThirdMobile from "./pages/ThirdMobile";
import SecondMobile from "./pages/SecondMobile";
import FourthMobile from "./pages/FourthMobile";
import FifthMobile from "./pages/FifthMobile";
import SixthMobile from "./pages/SixthMobile";
import SeventhMobile from "./pages/SeventhMobile";
import EighthMobile from "./pages/EighthMobile";
import TenthMobile from "./pages/TenthMobile";
import NinthMobile from "./pages/NinthMobile";
import TwelfthPage from "./pages/TwelfthPage";
import ProductList from "./components/ProductList";
import TwelfthMobile from "./pages/TwelfthMobile";
import ThirteenthPage from "./pages/ThirteenthPage";
import EleventhMobile from "./pages/EleventhMobile";
import ThirteenthMobile from "./pages/ThirteenthMobile";
import ProductListMobile from "./pages/ProductListMobile";
import ProductDetailsMobile from "./pages/ProductDetailsMobile";
import NewsDetailsMobile from "./components/NewsDetailsMobile";

import NewsDetails from "./components/NewsDetails";

export default function App() {
    const isMobile = function () {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    if (!isMobile()) {
        return (
            <div className="bg-white">
                <NavBar />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <FirstPage />
                                <SecondPage />
                                <ThirdPage />
                                <FourthPage />
                                <FifthPage />
                            </div>
                        }
                    />
                    <Route
                        path="/about-us"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <SixthPage />
                                <SeventhPage />
                                <EighthPage />
                            </div>
                        }
                    />
                    <Route
                        path="/contact-us"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <NinthPage />
                            </div>
                        }
                    />
                    <Route
                        path="/solutions"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <TenthPage />
                            </div>
                        }
                    />
                    <Route
                        path="/products"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <EleventhPage />
                            </div>
                        }
                    />
                    <Route
                        path={`/products/products-details-sample`}
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <ProductDetails />
                            </div>
                        }
                    />
                    <Route
                        path="/products-list"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <ProductList />
                            </div>
                        }
                    />
                    <Route
                        path="/csr"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <TwelfthPage />
                            </div>
                        }
                    />
                    <Route
                        path="/newsroom"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <ThirteenthPage />
                            </div>
                        }
                    />
                    <Route
                        path="/news/:id"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <NewsDetails />
                            </div>
                        }
                    />
                    <Route path="*" element={<div>404, Not Found!!</div>} />
                </Routes>
                <Contact />
                <Footer />
            </div>
        );
    } else {
        return (
            <div className="bg-white">
                <NavBarMobile />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div className="space-y-8 container mx-auto max-w-screen-2xl">
                                <FirstMobile />
                                <SecondMobile />
                                <ThirdMobile />
                                <FourthMobile />
                                <FifthMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/about-us"
                        element={
                            <div className="space-y-8 container mx-auto max-w-screen-2xl">
                                <SixthMobile />
                                <SeventhMobile />
                                <EighthMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/contact-us"
                        element={
                            <div className="space-y-8 container mx-auto max-w-screen-2xl">
                                <NinthMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/solutions"
                        element={
                            <div className="space-y-8 container mx-auto max-w-screen-2xl">
                                <TenthMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/products"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <EleventhMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/csr"
                        element={
                            <div className="space-y-8 container mx-auto max-w-screen-2xl">
                                <TwelfthMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/newsroom"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <ThirteenthMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/categories"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <ProductListMobile />
                            </div>
                        }
                    />
                    <Route
                        path={`/products/:productId`}
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <ProductDetailsMobile />
                            </div>
                        }
                    />
                    <Route
                        path="/sample-details"
                        element={
                            <div className="space-y-8 container mx-auto max-w-[96rem]">
                                <NewsDetailsMobile />
                            </div>
                        }
                    />
                    <Route path="*" element={<div>404, Not Found!!</div>} />
                </Routes>
                <ContactMobile />
                <FooterMobile />
            </div>
        );
    }
}
