import React, { useState } from "react";
import piracufImage from "../assets/images/piracuf-image.png";
import checkIcon from "../assets/icons/check.png";
import ProductCard from "./ProductCard";
import piracuf_1 from "../assets/images/piracuf-image-1.png";
import piracuf_2 from "../assets/images/piracuf-image-2.png";
import piracuf_3 from "../assets/images/piracuf-image-3.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/swiper-bundle.css";
import "../components/ProductDetails.css";

import products1Background from "../assets/images/products-1-background.jpg";

export default function ProductDetails({  }) {
    const products = {
        product_id: "P003",
        product_name: "Piracuf Syrup",
        product_details:
            "An herbal supplement for cough relief, and soothing sore throat. Formulated with a blend of 12 traditional herbs, Piracuf Syrup provides natural relief for respiratory discomfort in the throat.",
        product_description:
            "Piracuf Syrup: A herbal blend for cough relief and soothing sore throats, formulated with 12 traditional herbs for natural respiratory comfort.",
        benefits: [
            "Soothes sore throat.",
            "Supports healthy respiratory function.",
        ],
        contraindications: [
            "Hypersensitivity or allergy to Piracuf Syrup’s components.",
            "Pregnancy.",
            "Individuals with diarrhea, spleen or stomach disease, or yin deficiency.",
        ],
        dosage_and_administration: [
            "Children 1-6 years old: 5-10 ml, 2-3 times per day.",
            "Children 6-12 years old: 10 ml, 2-3 times per day.",
            "Children over 12 years old and adults: 15 ml, 2-3 times per day.",
        ],
        disclaimer: [
            "Consult a healthcare professional before use.",
            "This product is a dietary supplement and is not intended to be a substitute for medication.",
            "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
        ],
        storage:
            "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
        packaging: "Box of 1 bottle x 120ml",
        ingredients: [
            {
                ingredients_name: "Clerodenron cytophyllum Turcz",
                ingredients_amount: "750mg",
            },
            {
                ingredients_name: "Rhizoma Belamcandae chinensis",
                ingredients_amount: "750mg",
            },
            {
                ingredients_name: "Platycodon grandiflorum",
                ingredients_amount: "750mg",
            },
            {
                ingredients_name: "Fructus Trichosanthis",
                ingredients_amount: "750mg",
            },
            {
                ingredients_name: "Fructus Gardeniae",
                ingredients_amount: "584mg",
            },
            {
                ingredients_name: "Radix Peucedani",
                ingredients_amount: "584mg",
            },
            {
                ingredients_name: "Radix Scutellariae",
                ingredients_amount: "584mg",
            },
            {
                ingredients_name: "Radix Stemonae tuberosae",
                ingredients_amount: "584mg",
            },
            {
                ingredients_name: "Asparagus cochinchinensis",
                ingredients_amount: "584mg",
            },
            {
                ingredients_name: "Pericarpium Citri Reticulatae perene",
                ingredients_amount: "500mg",
            },
            {
                ingredients_name: "Cortex Mori Albae Radicis",
                ingredients_amount: "500mg",
            },
            {
                ingredients_name: "Bulbus Fritillariae cirrlosae",
                ingredients_amount: "500mg",
            },
        ],
        images: [piracuf_1, piracuf_2, piracuf_3],
    };

    const [product, setProduct] = useState({});

    React.useEffect(() => {
        
    }, []);

    const [selectedImage, setSelectedImage] = useState(products.images[0]);

    return (
        <div className="bg-white space-y-8 pb-16 rounded-md">
            <div className="flex flex-col shadow-xl">
                <div className="flex space-y-36 flex-col">
                    <div className="relative">
                        <img
                            src={products1Background}
                            alt="Piracuf Syrup"
                            className="opacity-15"
                        />
                        <div className="absolute inset-0 w-full h-full">
                            <div className="">
                                <div className="flex justify-between h-full px-48 pt-12 pb-28 items-center space-x-12">
                                    <div className="w-[40%]">
                                        <Swiper
                                            className="mySwiper"
                                            spaceBetween={8}
                                            slidesPerView={1}
                                            pagination={{ clickable: true }}
                                            navigation
                                            loop={true}
                                            modules={[Navigation, Pagination]}
                                        >
                                            {products.images.map(
                                                (image, index) => (
                                                    <SwiperSlide
                                                        className="flex bg-gray-200 py-16 px-16 rounded-2xl justify-center items-center"
                                                        key={index}
                                                    >
                                                        <div className="">
                                                            <img
                                                                src={image}
                                                                alt={`piracuf-${index}`}
                                                                className=""
                                                                onClick={() =>
                                                                    setSelectedImage(
                                                                        image
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper>
                                    </div>
                                    <div className="w-1/2 space-y-20">
                                        <div className="space-y-2">
                                            <h1 className="text-[42px] font-montserrat font-bold text-[#0D9149]">
                                                {products.product_name}
                                            </h1>
                                            <p className="text-[13px] text-justify font-roboto text-gray-800">
                                                {products.product_details}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <ul className="space-y-2">
                                                {products.benefits.map(
                                                    (benefit, index) => (
                                                        <li
                                                            key={index}
                                                            className="text-[20px] font-bold font-roboto items-center text-black"
                                                        >
                                                            <img
                                                                src={checkIcon}
                                                                alt="check"
                                                                className="w-6 h-6 inline-block mr-2"
                                                            />
                                                            {benefit}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-x-20 px-48 flex">
                                    <div className="space-y-4 w-1/2">
                                        <h2 className="text-[16px] font-roboto font-bold text-[#0D9149]">
                                            Ingredients
                                        </h2>
                                        <table className="w-[90%] font-roboto border border-gray-300">
                                            <tbody>
                                                {products.ingredients.map(
                                                    (ingredient, index) => (
                                                        <tr
                                                            key={index}
                                                            className="border-b bg-[#9ca49e] border-gray-100"
                                                        >
                                                            <td className="py-2 border-r px-4 text-center text-[13px] text-white">
                                                                {
                                                                    ingredient.ingredients_amount
                                                                }
                                                            </td>
                                                            <td className="py-2 px-4 text-[13px] text-white">
                                                                {
                                                                    ingredient.ingredients_name
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="w-1/2 grid font-roboto grid-cols-1 sm:grid-cols-1 gap-x-4 space-y-4">
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-roboto font-bold text-[#0D9149]">
                                                Contraindications
                                            </h2>
                                            <ul className="">
                                                {products.contraindications.map(
                                                    (
                                                        contraindication,
                                                        index
                                                    ) => (
                                                        <li
                                                            key={index}
                                                            className="text-[13px] text-black"
                                                        >
                                                            {contraindication}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-roboto font-bold text-[#0D9149]">
                                                Dosage and Administration
                                            </h2>
                                            <ul className="">
                                                {products.dosage_and_administration.map(
                                                    (dosage, index) => (
                                                        <li
                                                            key={index}
                                                            className="text-[13px] text-black"
                                                        >
                                                            {dosage}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-roboto font-bold text-[#0D9149]">
                                                Disclaimer
                                            </h2>
                                            <ul className="">
                                                {products.disclaimer.map(
                                                    (disclaimer, index) => (
                                                        <li
                                                            key={index}
                                                            className="text-[13px] text-black"
                                                        >
                                                            {disclaimer}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-roboto font-bold text-[#0D9149]">
                                                Storage
                                            </h2>
                                            <p className="text-[13px] text-black">
                                                {products.storage}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-roboto font-bold text-[#0D9149]">
                                                Packaging
                                            </h2>
                                            <p className="text-[13px] text-black">
                                                {products.packaging}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-x-20 px-48 flex"></div>
            </div>
            <div className="space-y-8 px-48">
                <h1 className="text-3xl text-[#2E6D70] font-bold text-start">
                    Similar Products
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-3 px-24 gap-4">
                    <ProductCard product={products} />
                    <ProductCard product={products} />
                    <ProductCard product={products} />
                </div>
            </div>
        </div>
    );
}
